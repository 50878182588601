@use "../colors.scss";

section#press {
    background: linear-gradient(60deg, colors.$tertiary, colors.$quaternary);
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        .header {
            width: 100%;
        }

        .press-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                text-decoration: none;
                color: #fff;

                .press-item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 8px;
                    background-color: rgba(#fff, 0.03);
                    border-radius: 0.5em;
                    width: 100%;
                    max-width: 280px;
                    text-align: left;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 20px;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }

                    img {
                        border: 0px;
                    }

                    .article-image {
                        width: 100%;
                        height: 200px;
                        border-radius: 0.5em;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    .title {
                        margin-top: 16px;
                        margin-bottom: 8px;
                    }

                    .publisher-image {
                        width: 80px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        min-height: calc(100vh - 64px);

        .container {
            width: 90vw;

            .header {
                margin-bottom: 10vh;

                h2 {
                    text-align: center;
                    font-size: 2.75em;
                }

                h3 {
                    text-align: center;
                }
            }

            .press-container {
                flex-direction: column;
                gap: 8px;

                img {
                    max-width: 80vw;
                }
            }
        }
    }

    @media only screen and (min-width: 600px) {
        height: calc(100vh - 64px);
        overflow: hidden;

        .container {
            width: 64vw;

            .header {
                margin-bottom: 15vh;

                h2, h3 {
                    text-align: center;
                }
            }

            .press-container {
                gap: 16px;

                img {
                    max-width: 12.5vw;
                }
            }
        }
    }
}