@use "../colors.scss";

section#contact {
    background: linear-gradient(15deg, colors.$tertiary, colors.$quaternary);
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;

        .header {
            h2 {
                font-size: 3em;
                margin-bottom: 0px;
            }

            h3 {
                font-size: 1.25em;
                font-weight: 600;;
                color: colors.$primary;
            }

            p {
                line-height: 30px;
                color: #ccc;
                font-size: 1.1em;
            }

            .social-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 0.5em;
                    background-color: rgba(#fff, 0.075);
                    color: colors.$primary;
                    transition: all 0.2s linear;

                    &:hover {
                        transform: translateY(-15%);
                        background-color: colors.$primary;
                        color: #fff;
                    }
                }
            }
        }
    }

    .logo {
        width: 50%;

        img {
            width: 60%;
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            width: 90vw;

            .header {
                width: 100%;

                .social-buttons {
                    justify-content: center;
                }
            }

            .logo {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 600px) {
        .container {
            width: 64vw;

            .header {
                width: 50%;
                text-align: left;

                .social-buttons {
                    justify-content: flex-start;
                }
            }
        }
    }
}