@use "../colors.scss";

section#splash {
    margin: 0;
    background-color: colors.$quaternary;
    background-image: url("../../public/images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
        display: flex;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 24px;

            .title {
                h2 {
                    position: relative;
                    z-index: 1;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        background-position: bottom;

        .overlay {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-top: 25px;
            background: linear-gradient(to bottom, #18201b, #18201b, rgba(24, 32, 27, 0.6), rgba(24, 32, 27, 0.25));

            .container {
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;

                .title {
                    margin-bottom: 60%;

                    h2 {
                        font-size: 2.75em;
                    }

                    .launch-app-container {
                        display: block;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 600px) {
        background-position: bottom right;

        .overlay {
            align-items: center;
            background: linear-gradient(to right, #18201b, #18201b, rgba(24, 32, 27, 0.25));

            .container {
                justify-content: flex-start;
                width: 64vw;

                .title {
                    h2 {
                        text-align: left;
                        font-size: 4em;
                    }

                    .launch-app-container {
                        display: none;
                    }
                }
            }
        }
    }
}

#app-body.hide-transforms .blink {
    visibility: hidden;
}