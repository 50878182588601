@use "../colors.scss";

section#team {
    background: linear-gradient(-30deg, colors.$tertiary, colors.$quaternary);
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        .team-container {
            display: flex;
            align-items: center;
            width: 100%;

            .team-member {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba(#fff, 0.03);
                border-radius: 0.5rem;
                width: 20vw;

                .team-member-image {
                    width: calc(100% - 2.4em);
                    max-height: 25vh;
                    border-radius: 0.5rem;
                    margin-top: 1.2em;
                    margin-bottom: 0.75em;
                    overflow: hidden;

                    & > img {
                        border-radius: 0.5rem;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                h4 {
                    margin-block-start: 0.5em;
                    margin-block-end: 0.3em;
                    text-transform: uppercase;
                    font-size: 1.25em;
                }

                h5 {
                    margin-block-start: 0.4em;
                    margin-block-end: 0em;
                    text-transform: uppercase;
                    color: colors.$primary;
                    font-size: 1em;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    list-style: none;
                    padding-inline-start: 0px;
                    font-size: 15px;
                    margin-top: 0px;
                    margin-left: 1.2em;
                    margin-right: 1.2em;

                    li {
                        text-align: center;
                        margin-bottom: 12px;
                    }
                }

                a {
                    img {
                        border: 0;
                        width: 18px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1080px) {
        .container {
            width: 90vw;

            .header {
                margin-bottom: 5vh;

                h2 {
                    margin-top: 64px;
                    font-size: 2.75em;
                }
            }

            .team-container {
                flex-direction: column;
                justify-content: center;
                gap: 32px;
                padding-bottom: 64px;

                .team-member {
                    width: 80vw;
                    max-width: 320px;

                    ul {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1080px) {
        height: calc(100vh - 64px);
        overflow: hidden;

        .container {
            width: 64vw;
            .header {
                margin-bottom: 5vh;
            }

            .team-container {
                align-items: flex-start;
                justify-content: space-evenly;

                .team-member {
                    ul {
                        min-height: 195px;
                    }
                }
            }
        }
    }
}