@use "../colors.scss";

section#pass {
    background: linear-gradient(-45deg, colors.$tertiary, colors.$quaternary);
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;

        .video-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .mobile {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                max-width: 300px;
                min-height: 460px;

                img {
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                }

                video {
                    position: absolute;
                    z-index: 1;
                    width: 70%;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            width: 90vw;
            flex-direction: column;

            .video-container {
                width: 100%;
            }

            .header {
                width: 100%;

                h2 {
                    text-align: center;
                    font-size: 1.6em !important;
                }

                h3 {
                    text-align: center;
                    font-size: 1.2em !important;
                }
            }
        }
    }

    @media only screen and (min-width: 600px) {
        .container {
            width: 64vw;

            .video-container {
                width: 50%;
                height: 100%;
            }

            .header {
                width: 50%;

                h2 {
                    font-size: 3rem !important;
                }

                h2, h3 {
                    text-align: left;
                }
            }
        }
    }
}