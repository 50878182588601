@use "./colors.scss";

$width: 1280px;

#app {
	text-align: center;

	header#app-header {
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 2;
		width: calc(100% - 32vw);
		height: 64px;
		display: flex;
		align-items: center;
		color: #fff;
		padding-left: 16vw;
		padding-right: 16vw;
		transition: all 0.2s linear;

		@media only screen and (min-width: 600px) {
			justify-content: space-between;
		}

		@media only screen and (max-width: 600px) {
			justify-content: center;
		}

		h1 {
			margin: 0px;
		}

		#app-logo {
			margin: 20px 16px 12px 16px;
			padding-left: 16px;
			height: 32px;
		}

		#menu {
			& > button {
				position: fixed;
				top: 22px;
				right: 16px;
				font-size: 20px;
			}

			ul {
				display: flex;
				align-items: center;
				list-style-type: none;
				padding: 0px;
				margin: 0px;

				li {
					white-space: nowrap;
					cursor: pointer;
					font-weight: 600;
				}
			}

			@media only screen and (min-width: 600px) {
				& > button {
					display: none;
				}

				ul {
					margin-right: 32px;
					gap: 24px;

					li {
						flex: 1;

						&#close-menu-button-container {
							display: none;
						}

						&#open-app-menu-item {
							display: block;							
						}
					}
				}
			}

			@media only screen and (max-width: 600px) {
				ul {
					position: fixed;
					top: 0px;
					left: -1px;
					flex-direction: column;
					width: 0px;
					height: 100vh;
					overflow: hidden;
					background-color: colors.$tertiary;
					transition: all 0.2s linear;

					&.show {
						width: 100%;
					}

					li {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: 64px;
						border-bottom: 1px solid colors.$secondary;

						&#close-menu-button-container {
							justify-content: flex-end;
							padding-right: 20px;

							button {
								font-size: 20px;
							}
						}

						&#open-app-menu-item {
							padding-top: 10px;
							border: 0px;
						}
					}
				}
			}
		}
	}

	#app-body {
		section {
			width: 100%;
			min-height: 100vh;

			.header {
				h2 {
					font-size: 56px;
					font-weight: 700;
					margin-bottom: 0px;
				}

				h3 {
					color: colors.$primary;
					font-size: 24px;
					font-weight: 600;
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}

	footer {
		background: linear-gradient(0deg, colors.$tertiary, colors.$quaternary);
	}
}