@use "./colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap');

body {
	margin: 0;
	background-color: colors.$quaternary;
	color: #fff;
	font-family: 'Montserrat', 'Urbanist', 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	font-family: 'Montserrat', 'Urbanist', 'Inter', sans-serif;
}

button.invisible {
	color: #fff;
	border: 0px;
	background-color: transparent;
}

button.launch-app {
	border: 0px;
	border-radius: 0.5em;
	padding: 12px;
	font-size: 15px;
	background-color: colors.$primary;
	color: #fff;
	font-weight: 600;
}

.primary {
	color: colors.$primary;
}

.blink {
	animation: blink 0.7s infinite alternate;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: colors.$quaternary; 
}

::-webkit-scrollbar-thumb {
	background: colors.$tertiary; 
}

::-webkit-scrollbar-thumb:hover {
	background: colors.$secondary; 
}

@keyframes blink {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}